import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M1201 3074 c-181 -39 -358 -191 -413 -355 l-16 -46 142 -6 c215 -10
474 -8 456 4 -8 5 -61 37 -117 70 -57 34 -103 64 -103 67 0 18 172 88 189 78
5 -3 22 -1 38 5 41 14 139 -3 208 -36 30 -14 58 -25 64 -25 6 0 40 -28 76 -61
48 -46 75 -81 100 -134 19 -40 35 -83 36 -96 0 -13 5 -33 10 -44 14 -31 1 123
-17 191 -20 77 -109 211 -177 264 -57 46 -120 80 -182 100 -22 7 -45 17 -51
22 -20 15 -175 17 -243 2z m583 -279 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0
8 2 15 4 15 2 0 6 -7 10 -15z"/>
          <path d="M2925 2680 c3 -6 -1 -7 -9 -4 -29 11 -19 -35 10 -48 22 -10 28 -10
40 6 17 24 2 56 -27 56 -11 0 -17 -4 -14 -10z"/>
          <path d="M2087 2673 c-4 -3 -7 -91 -7 -194 l0 -188 123 2 122 2 0 25 c0 25 -1
25 -100 31 -55 3 -94 6 -87 7 10 1 13 16 10 55 -2 42 0 51 12 49 8 -2 45 -1
81 3 55 6 66 10 64 23 -2 10 -4 20 -4 25 -1 4 -34 7 -75 7 l-74 0 -4 48 c-3
26 -3 50 -1 55 2 4 44 7 94 7 88 0 89 0 89 25 l0 25 -118 0 c-65 0 -122 -3
-125 -7z"/>
          <path d="M3049 2519 c-1 -90 -2 -176 -3 -191 -2 -16 -2 -28 -2 -29 1 0 30 -3
66 -5 68 -5 160 11 160 28 0 5 4 7 9 4 17 -11 67 42 81 83 17 53 8 155 -17
190 -34 46 -106 75 -205 79 l-88 4 -1 -163z m201 86 c58 -30 77 -156 32 -211
-27 -33 -72 -50 -122 -44 l-45 5 -3 110 c-4 153 -3 155 59 155 27 0 63 -7 79
-15z"/>
          <path d="M3756 2632 c-24 -3 -27 -7 -24 -27 5 -26 -11 -38 -37 -29 -11 5 -15
-1 -15 -19 0 -19 6 -26 23 -29 21 -3 22 -9 27 -97 5 -87 7 -95 34 -118 24 -20
36 -23 69 -19 48 7 51 9 42 35 -4 14 -13 20 -28 17 -48 -8 -57 8 -57 99 l0 85
33 1 c17 1 37 1 44 0 6 -1 11 8 10 19 -1 20 -8 23 -57 24 -20 1 -26 7 -30 31
-4 26 -9 30 -34 27z"/>
          <path d="M2435 2564 c-52 -18 -69 -34 -53 -51 11 -10 20 -9 54 7 33 16 47 19
68 10 60 -22 41 -65 -35 -75 -31 -4 -47 -11 -42 -18 4 -6 0 -8 -9 -4 -20 7
-45 -20 -52 -55 -5 -29 17 -68 40 -68 8 0 14 -4 14 -10 0 -20 57 -10 80 15 26
27 50 33 50 11 0 -16 21 -26 56 -26 30 0 34 35 4 43 -18 5 -20 14 -20 94 0 76
-3 92 -20 111 -12 12 -28 22 -38 23 -9 0 -27 2 -39 3 -12 2 -38 -3 -58 -10z
m101 -155 c2 -55 -41 -88 -89 -70 -19 7 -27 18 -27 35 0 29 19 46 63 56 44 11
52 7 53 -21z"/>
          <path d="M2656 2573 c-2 -2 -1 -13 1 -25 5 -16 12 -19 42 -15 20 2 48 0 64 -6
l27 -9 -29 -36 c-16 -21 -50 -59 -75 -86 -31 -34 -46 -59 -46 -77 l0 -26 93
-1 c109 -1 127 3 127 29 0 17 -7 19 -64 19 -36 0 -67 4 -70 9 -5 8 29 60 61
91 37 37 68 84 69 106 1 20 -5 23 -55 29 -57 6 -138 5 -145 -2z"/>
          <path d="M3487 2566 c-52 -19 -70 -35 -57 -52 9 -10 19 -9 53 7 54 24 73 24
91 -3 24 -33 5 -55 -54 -63 -30 -4 -47 -11 -44 -17 4 -6 -1 -8 -13 -4 -14 4
-23 -2 -36 -23 -23 -39 -22 -55 7 -88 31 -36 93 -39 128 -5 l24 22 17 -21 c12
-13 27 -19 45 -17 33 4 42 33 12 41 -18 5 -20 13 -20 85 0 100 -9 126 -51 141
-40 14 -55 13 -102 -3z m98 -167 c-2 -16 -12 -40 -22 -53 -17 -21 -22 -22 -53
-12 -64 20 -42 96 27 96 13 0 23 5 23 11 0 8 4 8 14 0 9 -7 13 -24 11 -42z"/>
          <path d="M3985 2571 c-11 -5 -30 -11 -42 -16 -12 -4 -24 -13 -27 -20 -6 -18
23 -43 32 -28 10 15 77 32 101 26 10 -3 21 -13 24 -23 8 -32 -12 -49 -64 -56
-89 -12 -131 -77 -85 -131 31 -36 90 -39 125 -4 l23 23 14 -21 c21 -29 84 -30
84 -2 0 11 -9 23 -20 26 -18 6 -20 15 -20 83 0 91 -5 114 -29 129 -24 15 -92
23 -116 14z m88 -172 c-2 -35 -32 -69 -60 -69 -44 0 -71 52 -40 78 14 12 78
31 92 28 6 -2 9 -18 8 -37z"/>
          <path d="M2902 2436 c0 -130 1 -134 22 -138 44 -9 46 -3 46 138 l0 134 -34 0
-35 0 1 -134z m51 117 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"/>
          <path d="M735 2460 c4 -83 6 -93 30 -165 56 -164 196 -300 368 -355 74 -24
209 -30 287 -12 75 16 186 74 248 128 63 55 142 164 100 138 -7 -4 -8 -3 -4 5
4 6 11 9 16 6 7 -4 40 52 38 65 -1 3 4 15 10 26 11 20 9 22 -21 27 -17 3 -151
5 -297 6 l-265 2 55 -35 c30 -19 79 -50 108 -67 28 -18 52 -37 52 -41 0 -15
-103 -57 -183 -74 -78 -17 -80 -17 -170 6 -121 30 -160 51 -232 124 -67 68
-113 151 -131 236 l-12 55 3 -75z m538 -527 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12
5 14 0 19 -2 13 -5z"/>
          <path d="M1872 2440 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
